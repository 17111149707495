$(function () {

    /****************************************
     * 下層(News page)
     ****************************************/

    (function () {
        // menu tab active
        $('.list-horizontal li a').click(function () {
            $('.list-horizontal li').removeClass('current');
            $(this).parent('li').addClass('current');
        });
    }).call(this);

  /****************************************
   * SP プルダウン変更で一覧切り替え
   ****************************************/
  (function () {
    $('#js-news_pulldown__sp').change(function () {
      let targetURL = $(this).val();
      location.href = targetURL;
    });
  }).call(this);

});
